import React from "react";
import NumberAnimation from './NumberAnimation';

const StatsSection = () => {
  const stats = [
    { value: "20", icon: "", label: "Global Clients" },
    { value: "3", icon: "+", label: "Years in Business" },
    { value: "50", icon: "+", label: "Projects Delivered" },
    // { value: "100", icon: "K", label: "Lines of Code Written" },
    { value: "95", icon: "%", label: "Client SatisTacition Rate" },
    // { value: "200", icon: "K", label: "Innovative Solutions" },
    // { value: "50", icon: "+", label: "Certified Experts" },
    // { value: "300", icon: "+", label: "Technology Partnerships" }
  ];
  return (
    <div className="w-[85%] max-[780px]:w-[90%] mx-auto bg-gradient-to-b from-[rgba(237,249,255,0.2)] to-[rgba(52,227,255,0.6)] h-[362px] max-[720px]:h-auto max-[720px]:p-[20px] flex items-center justify-center">
      <div className="flex justify-around items-center w-full max-[720px]:flex-col">
        {stats.map((stat, index) => (
          <div key={index} className="text-white">
            <NumberAnimation n={stat.value} icon={stat.icon} />
            <div className="w-12 h-1 bg-[#050D36] mx-auto my-2"></div>
            <p className="text-[22px] font-bold min-[720px]:text-[14px] min-[800px]:text-[16px] min-[920px]:text-[18px] min-[1000px]:text-[20px] min-[1065px]:text-[22px]">{stat.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsSection;
