import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/home/Home';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Portfolio from './components/portfolio/Portfolio';
import Blog from './components/blog/Blog';
import ContactUs from './components/contactUs/ContactUs';
import ServicesPage from './components/servicesPage/ServicesPage';
import WebDevelopment from './components/servicesPage/pages/WebDevelopment';
import ServiceOutlet from './components/servicesPage/ServiceOutlet';
import AppDevelopment from './components/servicesPage/pages/AppDevelopment';
import Marketing from './components/servicesPage/pages/Marketing';
import UIUX from './components/servicesPage/pages/UIUX';
import Designing from './components/servicesPage/pages/Designing';
import ARVRDevelopment from './components/servicesPage/pages/ARVRDevelopment';
import ArchitecturalDesigning from './components/servicesPage/pages/ArchitecturalDesigning';
import SingleService from './components/servicesPage/SingleService';
const App = () => {
  const location = useLocation();
  const getGradientForPage = () => {
    switch (location.pathname) {
      case '/':
        return 'bg-gradient-to-b from-[#050D36] to-[#050D36]';
      case '/services':
        return 'bg-gradient-to-b from-[#050D36] to-[#050D36]';
      case '/portfolio':
        return 'bg-gradient-to-b from-[#3A299F] via-[#05119D] to-[#2351F5]';
      case '/blog':
        return 'bg-gradient-to-b from-[#3165CB] to-[#030115]';
      case '/contactus':
        return 'bg-gradient-to-b from-[#050D36] to-[#050D36]';
      default:
        return 'bg-gradient-to-b from-[#39289F] to-[#422F9C]';
    }
  };

  return (
    <div className={`bg-gradient-to-b from-[#050D36] to-[#050D36] font-bodyFont text-white w-full`}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServiceOutlet />}>
          <Route path="" element={<ServicesPage />} />
          <Route path="web-development" element={<SingleService />} />
          <Route path="app-development" element={<SingleService />} />
          <Route path="marketing" element={<SingleService />} />
          <Route path="ui" element={<SingleService />} />
          <Route path="designing" element={<SingleService />} />
          <Route path="ar-vr-development" element={<SingleService />} />
          <Route path="architectural-designing" element={<SingleService />} />
        </Route>
        {/* <Route path="/portfolio" element={<Portfolio />} /> */}
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/contactus" element={<ContactUs />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <Footer />
    </div>
  );
};

export default App;