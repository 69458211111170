import React, { useState, useEffect } from 'react';

const   DynamicTextBanner = () => {
    const words = ['DESIGN', 'DEVELOPMENT', 'MARKETING', 'MAINTENANCE'];
    const textColor = ['#000AFF', '#FFA500', '#FF0000', '#FAFF00'];
    const [currentColor, setCurrentColor] = useState(textColor[0]);
    const [currentWord, setCurrentWord] = useState(words[0]);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        let index = 0;

        const interval = setInterval(() => {
            setFade(true);

            setTimeout(() => {
                index = (index + 1) % words.length; // Loop through words
                setCurrentColor(textColor[index])
                setCurrentWord(words[index]);
                setFade(false); // Remove fade-out after changing the word
            }, 500); // Duration of fade-out animation

        }, 3000); // Change word every 3 seconds

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, []);

    return (
        <div className='flex items-center w-full h-full justify-center relative z-[1]'>
            <h1 className='text-[225px] text-[#0AC4FF] uppercase max-[770px]:text-[100px] md:max-lg:text-[200px]'>Tacit</h1>
            <div className="absolute w-[85%] max-[770px]:w-[90%] backdrop-blur-md bg-white/10 rounded text-[51px] max-[770px]:text-[25px] md:max-lg:text-[30px] font-bold max-[770px]:font-semibold text-center py-[40px] max-[720px]:py-[20px] md:max-lg:py-[30px] bottom-[20%] max-[600px]:bottom-[25%] min-[1400px]:bottom-[20%] min-[1540px]:bottom-[23%] min-[1640px]:bottom-[26%] min-[1800px]:bottom-[30%] min-[2400px]:bottom-[33%] max-[720px]:flex flex-col max-[720px]:leading-[22px]">
                <span>WE ARE YOUR </span>
                <span style={{ color: currentColor }} className={`m-2 ${fade ? 'fade-out' : 'fade-in'}`}>
                    {currentWord}
                </span>
                <span>PARTNER</span>
            </div>
        </div>
    );
};

export default DynamicTextBanner;