import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import SubServices from './pages/SubServices';
import HeadingsServices from './pages/HeadingsServices';
import IconsDiv from './pages/IconsDiv';
import HaveAProject from '../common/HaveAProject';
import {
    AdobeCreativeSuiteIcon, AdobeXDIcon, AwsIcon, AxureIcon, CanvaIcon, CssIcon,
    FigmaIcon, FirebaseIcon, FlutterIcon, GitIcon,
    GoogleAdsIcon, GoogleAnalyticsIcon, HootsuiteIcon, HtmlIcon,
    HubSpotIcon, InVisionIcon, JSIcon, MongoIcon, NodeIcon,
    ProtoIoIcon,
    ReactIcon,
    SketchIcon,
    ZeplinIcon
} from './pages/Svgs';


const services = {
    "services": [
        {
            "id": "web-development",
            "title": "Web Development",
            "description": "At Tacit, we specialize in delivering modern, user-friendly websites tailored to your business needs. Our team combines creativity with cutting-edge technology to create solutions that not only look great but also perform exceptionally.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "Custom Web Development",
                        "para": [
                            "Tailored solutions for businesses of all sizes.",
                            "Responsive designs to ensure seamless performance across devices.",
                            "Intuitive user interfaces and enhanced user experiences."
                        ]
                    },
                    {
                        "title": "E-commerce Development",
                        "para": [
                            "Secure online stores with easy-to-use interfaces.",
                            "Integration of payment gateways and inventory management systems.",
                            "Scalable platforms designed for growth."
                        ]
                    },
                    {
                        "title": "Content Management Systems (CMS)",
                        "para": [
                            "Easy-to-update websites using CMS like WordPress, Drupal, or Joomla.",
                            "Custom plugins and module development."
                        ]
                    },
                    {
                        "title": "Web Application Development",
                        "para": [
                            "Interactive web applications for better user engagement.",
                            "Use of modern frameworks like React, Angular, and Vue.js."
                        ]
                    },
                    {
                        "title": "Website Maintenance & Support",
                        "para": [
                            "Regular updates to ensure security and functionality.",
                            "24/7 support for smooth operations."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "Quickly integrate client-centered users through vertical data. Holisticly repurpose interactive expert after distinctive resources. Collaboratively engineer prospective imperatives with transparent technology. Phosfluorescently morph excellent materials.",
                "icons": [
                    <HtmlIcon />,
                    <CssIcon />,
                    <JSIcon />,
                    <ReactIcon />,
                    <NodeIcon />,
                    <MongoIcon />,
                    <GitIcon />,
                ]
            }
        },
        {
            "id": "app-development",
            "title": "App Development",
            "description": "Unlock your app potential with Tacit's app development expertise. We specialize in creating high-performing, user-friendly applications tailored to your business needs. Combining creativity, cutting-edge technologies, and technical skills, we deliver seamless digital experiences that engage users and drive results.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "Native App Development",
                        "para": [
                            "Expert development for iOS and Android using native languages like Swift and Kotlin."
                        ]
                    },
                    {
                        "title": "Hybrid Apps",
                        "para": [
                            "Build cost-effective, versatile apps with frameworks like Flutter and React Native."
                        ]
                    },
                    {
                        "title": "Enterprise Apps",
                        "para": [
                            "Robust solutions for streamlining operations and improving productivity."
                        ]
                    },
                    {
                        "title": "E-commerce Apps",
                        "para": [
                            "Customized shopping apps with integrated payment gateways."
                        ]
                    },
                    {
                        "title": "Game Development",
                        "para": [
                            "Immersive gaming experiences using the latest technologies."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "Quickly integrate client-centered users through vertical data. Holisticly repurpose interactive expert after distinctive resources. Collaboratively engineer prospective imperatives with transparent technology. Phosfluorescently morph excellent materials.",
                "icons": [
                    <JSIcon />,
                    <FlutterIcon />,
                    <ReactIcon />,
                    <NodeIcon />,
                    <FirebaseIcon />,
                    <AwsIcon />,
                    <FigmaIcon />,
                ]
            }
        },
        {
            "id": "marketing",
            "title": "Digital Marketing",
            "description": "Transform your brand's presence with Tacit's cutting-edge marketing solutions. We craft data-driven strategies and creative campaigns that connect with your audience, boost engagement, and drive measurable results for your business.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "Digital Marketing",
                        "para": [
                            "Tailored strategies to enhance your online presence.",
                            "Engaging campaigns to drive traffic and conversions.",
                            "Comprehensive analytics to measure and optimize performance."
                        ]
                    },
                    {
                        "title": "Performance Marketing",
                        "para": [
                            "Result-driven advertising campaigns across platforms.",
                            "Optimized budgets for maximum ROI.",
                            "Precise audience targeting for effective outreach."
                        ]
                    },
                    {
                        "title": "Explainer Video",
                        "para": [
                            "Creative videos that communicate your ideas effectively.",
                            "Professional scriptwriting, animation, and voiceovers.",
                            "Custom designs tailored to your brand identity."
                        ]
                    },
                    {
                        "title": "Influencer Marketing",
                        "para": [
                            "Collaboration with influencers to amplify your brand.",
                            "Targeted campaigns for maximum reach and engagement.",
                            "Detailed tracking and reporting to evaluate impact."
                        ]
                    },
                    {
                        "title": "Meme Marketing",
                        "para": [
                            "Humorous and relatable content to boost brand visibility.",
                            "Creative strategies to connect with younger audiences.",
                            "Viral campaigns designed to spark conversations."
                        ]
                    },
                    {
                        "title": "SEO (Search Engine Optimization)",
                        "para": [
                            "Comprehensive keyword research for effective rankings.",
                            "On-page and off-page optimization to improve visibility.",
                            "Continuous monitoring and reporting to ensure long-term success."
                        ]
                    },
                    {
                        "title": "CGT Ads Service (Customized Geo-Targeted Ads)",
                        "para": [
                            "Hyper-local advertising campaigns to target specific regions.",
                            "Customized ad content based on user location for better engagement.",
                            "Real-time analytics to measure the performance of geo-targeted campaigns.",
                            "Ideal for businesses looking to reach audiences in specific cities, neighborhoods, or regions."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "Whether you're looking to grow your audience, improve conversions, or establish your brand, Tacit's marketing experts are here to help. Let's create campaigns that make an impact!",
                "icons": [
                    <GoogleAnalyticsIcon />,
                    <HootsuiteIcon />,
                    <GoogleAdsIcon />,
                    <CanvaIcon />,
                    <AdobeCreativeSuiteIcon />,
                    <HubSpotIcon />
                ]
            }
        },
        {
            "id": "ui",
            "title": "UI/UX Design",
            "description": "Deliver exceptional user experiences with Tacit's innovative UI/UX design services. We create visually stunning, user-friendly, and intuitive designs that drive engagement and ensure customer satisfaction.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "Wireframing & Prototyping",
                        "para": [
                            "Transform ideas into interactive wireframes for a clear visual structure.",
                            "Rapid prototyping to validate concepts and user flows.",
                            "Low-fidelity and high-fidelity prototypes for effective stakeholder feedback."
                        ]
                    },
                    {
                        "title": "Web Design",
                        "para": [
                            "Custom, modern, and responsive website designs that reflect your brand.",
                            "User-focused layouts to improve engagement and conversions.",
                            "Seamless performance across devices for an optimal user experience."
                        ]
                    },
                    {
                        "title": "Mobile App Design",
                        "para": [
                            "Intuitive and responsive designs for both iOS and Android platforms.",
                            "Focus on delivering exceptional usability and user experience.",
                            "Interactive and engaging interfaces designed to retain users."
                        ]
                    },
                    {
                        "title": "SaaS Product Design",
                        "para": [
                            "Scalable and user-centric designs for SaaS applications.",
                            "Focus on usability to simplify complex workflows.",
                            "Design systems to ensure consistency across your SaaS platform."
                        ]
                    },
                    {
                        "title": "Digital Product Redesign",
                        "para": [
                            "Revamp outdated products to improve usability and performance.",
                            "Modern designs aligned with current UI/UX trends.",
                            "Data-driven approach to redesign based on user behavior and feedback."
                        ]
                    },
                    {
                        "title": "UI/UX Design Consulting",
                        "para": [
                            "Expert advice on improving your product’s user experience.",
                            "User research and analysis to identify pain points and opportunities."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "Let Tacit redefine your digital presence with world-class UI/UX design solutions. From wireframes to interactive prototypes, we ensure designs that inspire and perform.",
                "icons": [
                    <FigmaIcon />,
                    <AdobeXDIcon />,
                    <SketchIcon />,
                    <InVisionIcon />,
                    <AxureIcon />,
                    <ProtoIoIcon />,
                    <ZeplinIcon />
                ]
            }
        },
        {
            "id": "designing",
            "title": "3D Designing",
            "description": "Transform your ideas into reality with Tacit's cutting-edge 3D designing services. We craft immersive, high-quality 3D visuals that bring your vision to life, enabling impactful communication and engagement.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "3D Modeling & Visualization",
                        "para": [
                            "High-quality 3D models for product visualization, architecture, and more.",
                            "Realistic rendering to bring your ideas to life.",
                            "Interactive 3D assets for AR/VR applications and games."
                        ]
                    },
                    {
                        "title": "2D Graphics & Illustrations",
                        "para": [
                            "Custom 2D illustrations for branding, marketing, and storytelling.",
                            "Vector-based designs for logos, icons, and promotional materials.",
                            "Unique character and concept art tailored to your needs."
                        ]
                    },
                    {
                        "title": "3D Animation",
                        "para": [
                            "Immersive 3D animations to create engaging experiences.",
                            "Realistic motion and visual effects for videos and presentations.",
                            "Product animations to showcase features and functionality."
                        ]
                    },
                    {
                        "title": "2D Animation",
                        "para": [
                            "Creative 2D animations for explainer videos, ads, and social media.",
                            "Smooth motion graphics to convey ideas effectively.",
                            "Character animation to add life to your stories."
                        ]
                    },
                    {
                        "title": "Game Asset Design",
                        "para": [
                            "3D and 2D assets for game environments, characters, and props.",
                            "Low-poly and high-poly models optimized for performance.",
                            "Custom textures and materials to enhance visual appeal."
                        ]
                    },
                    {
                        "title": "AR/VR Asset Creation",
                        "para": [
                            "Specialized 3D assets for AR and VR applications.",
                            "Optimized models for real-time performance.",
                            "Interactive objects to enhance user engagement in immersive environments."
                        ]
                    },
                    {
                        "title": "Architectural Design & Visualization",
                        "para": [
                            "Photo-realistic 3D architectural renderings.",
                            "Walkthrough animations to showcase properties and designs.",
                            "Interior and exterior visualizations for real estate and construction projects."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "At Tacit, we don’t just design—we create experiences. Partner with us for world-class 3D designing services that captivate and inspire.",
                "icons": []
            }
        },
        {
            "id": "ar-vr-development",
            "title": "AR/VR Development",
            "description": "Revolutionize the way you interact with the digital world through Tacit's advanced AR-VR development services. We create immersive and interactive experiences that blur the lines between the real and virtual, transforming how businesses engage with their audience.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "AR/VR Application Development",
                        "para": [
                            "Custom AR/VR solutions for various industries like education, healthcare, and real estate.",
                            "Immersive experiences that blend the physical and digital worlds.",
                            "Cross-platform development for mobile, web, and head-mounted displays (HMDs)."
                        ]
                    },
                    {
                        "title": "Virtual Reality Experiences",
                        "para": [
                            "Interactive VR environments for gaming, training, and simulations.",
                            "360-degree immersive experiences to engage users like never before.",
                            "Realistic and interactive virtual spaces designed to captivate audiences."
                        ]
                    },
                    {
                        "title": "Augmented Reality Solutions",
                        "para": [
                            "Marker-based and markerless AR experiences for mobile and web.",
                            "Product visualization, AR filters, and interactive content for marketing.",
                            "Integration with wearable devices like AR glasses for enhanced user interaction."
                        ]
                    },
                    {
                        "title": "AR/VR Game Development",
                        "para": [
                            "Immersive games designed for AR/VR platforms.",
                            "Real-time physics, interactions, and storytelling in virtual environments.",
                            "Support for various devices including Oculus, HTC Vive, and mobile AR."
                        ]
                    },
                    {
                        "title": "Spatial Sound Design",
                        "para": [
                            "Spatial audio integration for a more immersive AR/VR experience.",
                            "Dynamic soundscapes that adapt to user interactions and environments.",
                            "Realistic audio cues to enhance engagement and realism."
                        ]
                    },
                    {
                        "title": "AR/VR for Training & Simulations",
                        "para": [
                            "Realistic virtual training environments for industries like healthcare, defense, and manufacturing.",
                            "Interactive simulations to improve learning outcomes and reduce real-world risks.",
                            "Scenario-based VR solutions for employee training and skill development."
                        ]
                    },
                    {
                        "title": "WebAR Development",
                        "para": [
                            "Augmented reality experiences accessible directly through web browsers.",
                            "No app download required – seamless AR integration into websites.",
                            "Custom WebAR solutions for marketing, e-commerce, and brand engagement."
                        ]
                    },
                    {
                        "title": "Mixed Reality Solutions",
                        "para": [
                            "Blending real and virtual worlds for unique user experiences.",
                            "Development for devices like Microsoft HoloLens and Magic Leap.",
                            "Applications for industries like manufacturing, education, and retail."
                        ]
                    },
                    {
                        "title": "AR/VR Consultation",
                        "para": [
                            "Expert guidance on implementing AR/VR solutions for your business.",
                            "Idea validation, technical feasibility analysis, and strategy planning.",
                            "Post-deployment support to ensure optimal performance and user engagement."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "At Tacit, we don’t just design—we create experiences. Partner with us for world-class AR/VR development services that captivate and inspire.",
                "icons": []
            }
        },
        {
            "id": "architectural-designing",
            "title": "Architectural Designing",
            "description": "Bring your vision to life with Tacit's architectural designing services. We combine creativity, precision, and advanced technology to deliver innovative designs that redefine spaces and create timeless architectural masterpieces.",
            "expertise": {
                "heading": "Our Expertise",
                "services": [
                    {
                        "title": "Architectural 3D Modeling & Visualization",
                        "para": [
                            "Realistic 3D models for residential, commercial, and industrial projects.",
                            "High-quality rendering to showcase detailed architectural designs.",
                            "Exterior and interior visualizations to bring your projects to life."
                        ]
                    },
                    {
                        "title": "VR Walkthroughs",
                        "para": [
                            "Immersive virtual walkthroughs of your architectural designs.",
                            "Experience projects in a fully interactive 3D environment before construction.",
                            "Seamless navigation through spaces to visualize layouts and finishes.",
                            "Enhance client presentations with VR experiences using devices like Oculus and HTC Vive.",
                            "Web-based VR walkthroughs for easy access without special hardware."
                        ]
                    },
                    {
                        "title": "Floor Plan Designing",
                        "para": [
                            "Detailed 2D and 3D floor plans for better spatial understanding.",
                            "Accurate layouts with furniture placement and room measurements.",
                            "Custom floor plans based on client requirements."
                        ]
                    },
                    {
                        "title": "Interior Design Visualization",
                        "para": [
                            "Photorealistic interior designs with detailed textures and lighting.",
                            "Customized designs for residential, commercial, and retail spaces.",
                            "Material and furniture selection to match client preferences."
                        ]
                    },
                    {
                        "title": "Exterior Design & Landscaping",
                        "para": [
                            "Creative exterior designs that enhance curb appeal.",
                            "Landscaping plans for gardens, patios, and outdoor areas.",
                            "Lighting and environmental elements to create realistic renders."
                        ]
                    },
                    {
                        "title": "Architectural Animation",
                        "para": [
                            "Walkthrough videos showcasing the entire project flow.",
                            "Dynamic animations to highlight architectural features.",
                            "High-quality videos for marketing and presentations."
                        ]
                    },
                    {
                        "title": "BIM (Building Information Modeling)",
                        "para": [
                            "Detailed BIM models to improve project planning and execution.",
                            "Accurate 3D representations with structural, MEP, and design details.",
                            "Streamlined collaboration between architects, engineers, and contractors."
                        ]
                    },
                    {
                        "title": "AR/VR for Real Estate",
                        "para": [
                            "Augmented reality solutions to visualize properties in real time.",
                            "Interactive VR experiences to showcase properties to potential buyers.",
                            "Reduce the need for physical site visits with virtual property tours."
                        ]
                    },
                    {
                        "title": "Architectural Design Consulting",
                        "para": [
                            "Expert guidance on architectural design trends and innovations.",
                            "Feasibility studies and space optimization strategies.",
                            "Client-focused consulting to bring your vision to life."
                        ]
                    }
                ]
            },
            "technologies": {
                "heading": "Technologies We Use",
                "description": "At Tacit, we don’t just design—we create experiences. Partner with us for world-class architectural design services that captivate and inspire.",
                "icons": []
            }
        }
        
    ]
}

const SingleService = () => {
    const location = useLocation();
    const serviceId = location.pathname.split('/')[2];

    const service = services.services.find(s => s.id === serviceId);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!service) return null;

    return (
        <>
            <div className='flex flex-col items-center gap-[30px] w-[85%] mx-auto py-[20px]'>
                <HeadingsServices para={service.description} />
                <div className='flex flex-col gap-[20px]'>
                    <h1 className='font-semibold text-[25px]'>Our Expertise</h1>
                    <div className='w-full flex flex-wrap gap-[30px] pb-[20px]'>
                        {service.expertise.services.map((subService, index) => (
                            <SubServices
                                key={index}
                                title={subService.title}
                                para={subService.para}
                            />
                        ))}
                    </div>
                    {service.technologies.icons.length > 0 &&
                        <>
                            <h1 className='font-semibold text-[25px]'>Technologies We Use</h1>
                            <p className='text-[14px] max-[500px]:text-[13px]'>
                                {service.technologies.description}
                            </p>
                            <div className='flex flex-wrap gap-[30px]'>
                                {service.technologies.icons.map((icon, index) => (
                                    <IconsDiv key={index} icon={icon} />
                                ))}
                            </div>
                        </>
                    }
                </div>
            </div>
            <HaveAProject />
        </>
    );
};

export default SingleService;
