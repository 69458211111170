import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import bgImage2 from '../../images/hero.png';
import DynamicTextBanner from './DynamicTextBanner';
import HeaderForMobile from './HeaderForMobile';
import './Header.css';
import logo from '../../images/logo.png';

const Header = () => {
    const location = useLocation();
    const [headerForMobile, setHeaderForMobile] = useState(false);
    const [active, setActive] = useState(false);
    useEffect(() => {
        setHeaderForMobile(false);
    }, [location.pathname]);

    const getHeadingForPage = useMemo(() => {
        switch (location.pathname) {
            case '/':
                return 'HOME';
            case '/services':
                return 'SERVICES';
            case '/blog':
                return 'BLOG';
            case '/contactus':
                return 'CONTACT US';
            case '/services/web-development':
                return 'WEB DEVELOPMENT';
            case '/services/app-development':
                return 'APP DEVELOPMENT';
            case '/services/marketing':
                return 'Marketing';
            case '/services/ui':
                return 'UI/UX';
            case '/services/designing':
                return '3D Designing';
            case '/services/ar-vr-development':
                return 'AR/VR Development';
            case '/services/architectural-designing':
                return 'Architectural Designing';
            default:
                return '';
        }
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            setActive(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            {/* Sticky Navbar */}
            <div className={`top-0 z-50 bg-[#070B1D] bg-opacity-90 flex justify-between w-full py-[10px] px-[20px] ${active ? "sticky" : ""}`}>
                <div className="w-1/2">
                    <Link to="/">
                        <img className="w-[75px] h-[73px] max-[770px]:w-[45px] max-[770px]:h-[43px]" src={logo} alt="Logo" />
                    </Link>
                </div>
                <div className="header_buttons">
                    <Link to="/" className={location.pathname === '/' ? 'text-[#FA9021]' : 'hover:text-[#FA9021] duration-500'}>Home</Link>
                    <Link to="/services" className={location.pathname.includes('/services') ? 'text-[#FA9021]' : 'hover:text-[#FA9021] duration-500'}>Services</Link>
                    <Link to="/contactus" className={location.pathname === '/contactus' ? 'text-[#FA9021]' : 'hover:text-[#FA9021] duration-500'}>Contact Us</Link>
                </div>
                <div className="hamBurg" onClick={() => setHeaderForMobile(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="21" y1="10" x2="3" y2="10"></line>
                        <line x1="21" y1="6" x2="3" y2="6"></line>
                        <line x1="21" y1="14" x2="3" y2="14"></line>
                        <line x1="21" y1="18" x2="3" y2="18"></line>
                    </svg>
                </div>
                {headerForMobile && <HeaderForMobile setHeaderForMobile={setHeaderForMobile} />}
            </div>

            {/* Header Content */}
            {location.pathname === '/' ? (
                <div className="text-white h-screen bg-cover relative flex flex-col justify-between" style={{ backgroundColor: '#070B1D' }}>
                    <DynamicTextBanner />
                    <div className="flex justify-between p-[20px] z-[1]">
                        <div className="w-[45%] flex justify-between">
                            <p className="uppercase text-[12px]">We Are In Top</p>
                            <li className="text-[12px] custom-dot max-[720px]:hidden">
                                Everything you need to get the attention of your audience.
                            </li>
                        </div>
                        <p className="text-[12px]">#Tacit</p>
                    </div>
                    <div className="absolute z-0 w-full h-screen">
                        <svg width="100%" height="100%" viewBox="0 0 1032 612" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1030.25 12.4592C906.355 -18.0625 618.436 -1.34007 457.917 309.723C297.398 620.785 -81.7445 630.863 -251.251 597.019" stroke="#0A0F26" strokeWidth="2.25" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            ) : (
                <div className="text-white h-[60vh] bg-cover relative" style={{ backgroundImage: `url('${bgImage2}')` }}>
                    <div className="w-full h-full font-extrabold text-[55px] max-[720px]:text-[35px] absolute top-[40%] pl-[20px]">
                        {getHeadingForPage}
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;