// import React, { useState } from "react";
// import imag1 from '../../images/services/image 21.png'
// import imag2 from '../../images/services/image_20.png'
// import imag3 from '../../images/services/image_21.png'
// import imag4 from '../../images/services/image_22.png'
// import imag5 from '../../images/services/image_23.png'
// import imag6 from '../../images/services/image_24.png'
// import imag7 from '../../images/services/image_25.png'
// import imag8 from '../../images/services/image_26.png'
// import MobileSlider from "./MobileSlider";
// import './Slider.css'
// const servicesData1 = [
//   {
//     title: "Game Development",
//     description: "Experience immersive worlds with Tacit's game development expertise.",
//     image: imag2,
//   },
//   {
//     title: "Web Development",
//     description: "Custom websites that blend creativity and technology for impact.",
//     image: imag3,
//   },
//   {
//     title: "App Development",
//     description: "Custom Applications that blend creativity and technology for impact.",
//     image: imag4,
//   },
//   {
//     title: "Digital Marketing",
//     description: "Elevate your brand with strategic digital marketing solutions.",
//     image: imag1,
//   },
// ];
// const servicesData2 = [
//   {
//     title: "UI/UX Designing",
//     description: "Stunning UI/UX designs that enhance user engagement and experience.",
//     image: imag5,
//   },
//   {
//     title: "3D Designing",
//     description: "Transform ideas into stunning 3D visuals and immersive experiences.",
//     image: imag6,
//   },
//   {
//     title: "AR-VR Development",
//     description: "Create captivating AR/VR experiences that redefine immersive technology.",
//     image: imag7,
//   },
//   {
//     title: "Architectural Designing",
//     description: "Transform spaces with innovative architectural and interior design solutions.",
//     image: imag8,
//   },
// ];
// const Slider = () => {
//   const [currentSlide, setCurrentSlide] = useState(1);
//   const [currentService, setCurrentService] = useState(servicesData1[0]);
//   const [transitionClass, setTransitionClass] = useState("");

//   const handleNextSlide = () => {
//     setCurrentSlide(2);
//     setCurrentService(servicesData2[0]); // Automatically set first service of next slide
//   };

//   const handlePrevSlide = () => {
//     setCurrentSlide(1);
//     setCurrentService(servicesData1[0]); // Automatically set first service of previous slide
//   };

//   const handleServiceClick = (service) => {
//     setTransitionClass("opacity-0");
//     setTimeout(() => {
//       setCurrentService(service);
//       setTransitionClass("opacity-100");
//     }, 300);
//   };

//   return (
//     <div>
//       <div className="our_services_heading_div">
//         <h1 className="font-extrabold text-[30px] max-[900px]:text-[25px] uppercase pb-[10px]">our services </h1>
//         <p className="text-[15px] max-[900px]:text-[12px]">Dramatically supply transparent deliverables before award comp internal or "organic" sources. Comp transparent and leverage backward other.</p>
//       </div>
//       <div className="our_services_slider">
//         <div className={`our_services_slider_img_div transition-opacity duration-500 ${transitionClass}`}>
//           <img src={currentService.image} alt={currentService.title} className="our_services_slider_img object-contain" />
//         </div>
//         {/* First Slide with 4 services */}
//         {currentSlide === 1 && (
//           <div className="flex justify-around w-full transition-all items-center">
//             {servicesData1.map((service, index) => (
//               <div key={index} className="text-center cursor-pointer w-[25%]" onClick={() => handleServiceClick(service)}>
//                 <h3 className="our_services_slider_options font-semibold">{service.title}</h3>
//                 {currentService.title === service.title && <p className="text-center font-medium our_services_slider_options_para">{currentService.description}</p>}
//               </div>
//             ))}
//             <div className="absolute right-0 top-[45%] cursor-pointer" onClick={handleNextSlide}>
//               <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <circle cx="30.5" cy="30.5" r="28.5" stroke="white" strokeWidth="4" />
//                 <path d="M39.8483 37.8865L46.2265 31.5082C46.5849 31.1495 46.7861 30.6631 46.7861 30.1561C46.7861 29.649 46.5849 29.1627 46.2265 28.8039L39.8483 22.4257C39.4856 22.0877 39.0058 21.9037 38.5101 21.9124C38.0144 21.9212 37.5414 22.122 37.1908 22.4726C36.8403 22.8231 36.6395 23.2961 36.6307 23.7918C36.622 24.2875 36.806 24.7673 37.144 25.13L40.2565 28.2426H15.5346C15.0271 28.2426 14.5404 28.4442 14.1815 28.803C13.8227 29.1619 13.6211 29.6486 13.6211 30.1561C13.6211 30.6635 13.8227 31.1502 14.1815 31.5091C14.5404 31.8679 15.0271 32.0695 15.5346 32.0695H40.2565L37.144 35.1821C36.956 35.3573 36.8052 35.5685 36.7006 35.8032C36.596 36.038 36.5398 36.2913 36.5352 36.5483C36.5307 36.8052 36.578 37.0604 36.6742 37.2986C36.7704 37.5369 36.9137 37.7533 37.0954 37.935C37.2771 38.1167 37.4935 38.26 37.7318 38.3562C37.97 38.4524 38.2252 38.4997 38.4822 38.4952C38.7391 38.4906 38.9925 38.4344 39.2272 38.3298C39.4619 38.2252 39.6731 38.0745 39.8483 37.8865Z" fill="white" />
//               </svg>
//             </div>
//           </div>
//         )}
//         {/* Second Slide with 4 services */}
//         {currentSlide === 2 && (
//           <div className="flex justify-around w-full py-10 transition-all items-center">
//             {servicesData2.map((service, index) => (
//               <div key={index} className="text-center cursor-pointer w-[25%]" onClick={() => handleServiceClick(service)}>
//                 <h3 className="our_services_slider_options font-semibold">{service.title}</h3>
//                 {currentService.title === service.title && <p className="our_services_slider_options_para text-center font-medium">{currentService.description}</p>}
//               </div>
//             ))}
//             <div className="absolute left-0 top-[45%] cursor-pointer" onClick={handlePrevSlide}>
//               <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <circle cx="30.5" cy="30.5" r="28.5" transform="matrix(-1 0 0 1 61 0)" stroke="white" strokeWidth="4" />
//                 <path d="M21.1517 37.8865L14.7735 31.5082C14.4151 31.1495 14.2139 30.6631 14.2139 30.1561C14.2139 29.649 14.4151 29.1627 14.7735 28.8039L21.1517 22.4257C21.5144 22.0877 21.9942 21.9037 22.4899 21.9124C22.9856 21.9212 23.4586 22.122 23.8092 22.4726C24.1597 22.8231 24.3605 23.2961 24.3693 23.7918C24.378 24.2875 24.194 24.7673 23.856 25.13L20.7435 28.2426H45.4654C45.9729 28.2426 46.4596 28.4442 46.8185 28.803C47.1773 29.1619 47.3789 29.6486 47.3789 30.1561C47.3789 30.6635 47.1773 31.1502 46.8185 31.5091C46.4596 31.8679 45.9729 32.0695 45.4654 32.0695H20.7435L23.856 35.1821C24.044 35.3573 24.1948 35.5685 24.2994 35.8032C24.404 36.038 24.4602 36.2913 24.4648 36.5483C24.4693 36.8052 24.422 37.0604 24.3258 37.2986C24.2296 37.5369 24.0863 37.7533 23.9046 37.935C23.7229 38.1167 23.5065 38.26 23.2682 38.3562C23.03 38.4524 22.7748 38.4997 22.5178 38.4952C22.2609 38.4906 22.0075 38.4344 21.7728 38.3298C21.5381 38.2252 21.3269 38.0745 21.1517 37.8865Z" fill="white" />
//               </svg>
//             </div>
//           </div>
//         )}
//       </div>
//       <MobileSlider />
//     </div>
//   );
// };

// export default Slider;

import React, { useState } from "react";
import imag1 from '../../images/services/image 21.png';
import imag2 from '../../images/services/image_20.png';
import imag3 from '../../images/services/image_21.png';
import imag4 from '../../images/services/image_22.png';
import imag5 from '../../images/services/image_23.png';
import imag6 from '../../images/services/image_24.png';
import imag7 from '../../images/services/image_25.png';
import imag8 from '../../images/services/image_26.png';
import MobileSlider from "./MobileSlider";
import './Slider.css';

const servicesData1 = [
  {
    title: "Game Development",
    description: "Experience immersive worlds with Tacit's game development expertise.",
    image: imag2,
  },
  {
    title: "Web Development",
    description: "Custom websites that blend creativity and technology for impact.",
    image: imag3,
  },
  {
    title: "App Development",
    description: "Custom Applications that blend creativity and technology for impact.",
    image: imag4,
  },
  {
    title: "Digital Marketing",
    description: "Elevate your brand with strategic digital marketing solutions.",
    image: imag1,
  },
];

const servicesData2 = [
  {
    title: "UI/UX Designing",
    description: "Stunning UI/UX designs that enhance user engagement and experience.",
    image: imag5,
  },
  {
    title: "3D Designing",
    description: "Transform ideas into stunning 3D visuals and immersive experiences.",
    image: imag6,
  },
  {
    title: "AR-VR Development",
    description: "Create captivating AR/VR experiences that redefine immersive technology.",
    image: imag7,
  },
  {
    title: "Architectural Designing",
    description: "Transform spaces with innovative architectural and interior design solutions.",
    image: imag8,
  },
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentService, setCurrentService] = useState(servicesData1[0]);
  const [transitionClass, setTransitionClass] = useState("");

  const handleNextSlide = () => {
    setCurrentSlide(2);
    setCurrentService(servicesData2[0]); // Automatically set first service of next slide
  };

  const handlePrevSlide = () => {
    setCurrentSlide(1);
    setCurrentService(servicesData1[0]); // Automatically set first service of previous slide
  };

  // Handle hover (mouse enter) to change content
  const handleServiceHover = (service) => {
    if (service.title !== currentService.title) { // Only change content if it's not the current service
      setTransitionClass("opacity-0");
      setTimeout(() => {
        setCurrentService(service);
        setTransitionClass("opacity-100");
      }, 300);
    }
  };

  return (
    <div>
      <div className="our_services_heading_div">
        <h1 className="font-extrabold text-[30px] max-[900px]:text-[25px] uppercase pb-[10px]">our services </h1>
        <p className="text-[15px] max-[900px]:text-[12px]">Dramatically supply transparent deliverables before award comp internal or "organic" sources. Comp transparent and leverage backward other.</p>
      </div>
      <div className="our_services_slider">
        <div className={`our_services_slider_img_div transition-opacity duration-500 ${transitionClass}`}>
          <img src={currentService.image} alt={currentService.title} className="our_services_slider_img object-contain" />
        </div>

        {/* First Slide with 4 services */}
        {currentSlide === 1 && (
          <div className="flex justify-around w-full transition-all items-center">
            {servicesData1.map((service, index) => (
              <div 
                key={index} 
                className="text-center cursor-pointer w-[25%]" 
                onMouseEnter={() => handleServiceHover(service)} // on hover change content
                onMouseLeave={() => setTransitionClass("")} // reset transition when hover ends
              >
                <h3 className="our_services_slider_options font-semibold">{service.title}</h3>
                {currentService.title === service.title && <p className="text-center font-medium our_services_slider_options_para">{currentService.description}</p>}
              </div>
            ))}
            <div className="absolute right-0 top-[45%] cursor-pointer" onClick={handleNextSlide}>
              <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30.5" cy="30.5" r="28.5" stroke="white" strokeWidth="4" />
                <path d="M39.8483 37.8865L46.2265 31.5082C46.5849 31.1495 46.7861 30.6631 46.7861 30.1561C46.7861 29.649 46.5849 29.1627 46.2265 28.8039L39.8483 22.4257C39.4856 22.0877 39.0058 21.9037 38.5101 21.9124C38.0144 21.9212 37.5414 22.122 37.1908 22.4726C36.8403 22.8231 36.6395 23.2961 36.6307 23.7918C36.622 24.2875 36.806 24.7673 37.144 25.13L40.2565 28.2426H15.5346C15.0271 28.2426 14.5404 28.4442 14.1815 28.803C13.8227 29.1619 13.6211 29.6486 13.6211 30.1561C13.6211 30.6635 13.8227 31.1502 14.1815 31.5091C14.5404 31.8679 15.0271 32.0695 15.5346 32.0695H40.2565L37.144 35.1821C36.956 35.3573 36.8052 35.5685 36.7006 35.8032C36.596 36.038 36.5398 36.2913 36.5352 36.5483C36.5307 36.8052 36.578 37.0604 36.6742 37.2986C36.7704 37.5369 36.9137 37.7533 37.0954 37.935C37.2771 38.1167 37.4935 38.26 37.7318 38.3562C37.97 38.4524 38.2252 38.4997 38.4822 38.4952C38.7391 38.4906 38.9925 38.4344 39.2272 38.3298C39.4619 38.2252 39.6731 38.0745 39.8483 37.8865Z" fill="white" />
              </svg>
            </div>
          </div>
        )}

        {/* Second Slide with 4 services */}
        {currentSlide === 2 && (
          <div className="flex justify-around w-full py-10 transition-all items-center">
            {servicesData2.map((service, index) => (
              <div 
                key={index} 
                className="text-center cursor-pointer w-[25%]" 
                onMouseEnter={() => handleServiceHover(service)} 
                onMouseLeave={() => setTransitionClass("")}
              >
                <h3 className="our_services_slider_options font-semibold">{service.title}</h3>
                {currentService.title === service.title && <p className="our_services_slider_options_para text-center font-medium">{currentService.description}</p>}
              </div>
            ))}
            <div className="absolute left-0 top-[45%] cursor-pointer" onClick={handlePrevSlide}>
              <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="30.5" cy="30.5" r="28.5" transform="matrix(-1 0 0 1 61 0)" stroke="white" strokeWidth="4" />
                <path d="M21.1517 37.8865L14.7735 31.5082C14.4151 31.1495 14.2139 30.6631 14.2139 30.1561C14.2139 29.649 14.4151 29.1627 14.7735 28.8039L21.1517 22.4257C21.5144 22.0877 21.9942 21.9037 22.4899 21.9124C22.9856 21.9212 23.4586 22.122 23.8092 22.4726C24.1597 22.8231 24.3605 23.2961 24.3693 23.7918C24.378 24.2875 24.194 24.7673 23.856 25.13L20.7435 28.2426H45.4654C45.9729 28.2426 46.4596 28.4442 46.8185 28.803C47.1773 29.1619 47.3789 29.6486 47.3789 30.1561C47.3789 30.6635 47.1773 31.1502 46.8185 31.5091C46.4596 31.8679 45.9729 32.0695 45.4654 32.0695H20.7435L23.856 35.1821C24.044 35.3573 24.1948 35.5685 24.2994 35.8032C24.404 36.038 24.4602 36.2913 24.4648 36.5483C24.4693 36.8052 24.422 37.0604 24.3258 37.2986C24.2296 37.5369 24.0863 37.7533 23.9046 37.935C23.7229 38.1167 23.5065 38.26 23.2682 38.3562C23.03 38.4524 22.7748 38.4997 22.5178 38.4952C22.2609 38.4906 22.0075 38.4344 21.7728 38.3298C21.5381 38.2252 21.3269 38.0745 21.1517 37.8865Z" fill="white" />
              </svg>
            </div>
          </div>
        )}
      </div>
      <MobileSlider />
    </div>
  );
};

export default Slider;
