import React from 'react'

const HeadingsServices = ({ para }) => {
    return (
        <div className='web_dev_services_page_heading_div gap-[20px]'>
            {/* <h1 className='font-extrabold text-[30px] max-[500px]:text-[25px] max-[500px]:font-bold'>Why Choose Us?</h1> */}
            <p className='text-[14px] text-center max-[500px]:text-[13px]'>{para}</p>
        </div>
    )
}

export default HeadingsServices