import React from 'react'

const IconsDiv = ({ icon }) => {
    return (
        <div className="group relative w-[140px] h-[140px] flex items-center justify-center bg-[#202957] overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-[#EDF9FF50] to-[#34E3FF50] opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100"></div>
            <div className="relative z-10">
                {icon}
            </div>
        </div>
    )
}

export default IconsDiv