import React from 'react'

const SubServices = ({ title, para }) => {
    return (
        <div className='flex flex-col gap-[10px] w-[48%] max-[890px]:w-full'>
            <h1 className='font-medium text-[18px]'>{title}</h1>
            <div className='flex flex-col gap-[10px]'>
                {para.map((item, index) => (
                    <div key={index} className='flex gap-[10px] items-center'>
                        <div className='p-[10px] w-max rounded-full bg-gradient-to-b from-[#EDF9FF50] to-[#34E3FF50]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                        </div>
                        <p>{item}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SubServices